import React, { useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import ReactHlsPlayer from 'react-hls-player';

function App() {
  const playerRef = useRef();  
    
  return (
    <div className="App" style={{height: '100%'}}>
        <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', height: '100vh', width: '100%', overflow: 'hidden', margin: '0 auto'}}>
            <ReactHlsPlayer
                playerRef={playerRef}
                src="/stream/wedding.m3u8"
                autoPlay={true}
                controls={true}
                width="auto"
                height="100%"
                style={{display: 'block', objectFit: 'contain', objectPosition: 'center', width: '100%'}}
            />
        </div>
    </div>
  );
}

export default App;
